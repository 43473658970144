const DungeonGuideResultsStyler = (() => {
    const startTags = ["<bg_rgb(", "<txt_rgb(", "<bold>", "<underline>"];
    const endTags = ["</bg_rgb>", "</txt_rgb>", "</bold>", "</underline>"];

    const injectStyling = ((key, sourceString, topLevelCssRowClassNames, itemLevelCssRowClassNames) => {
        return styleAndRenderJsx(key, sourceString, topLevelCssRowClassNames, itemLevelCssRowClassNames);
    });

    // Because jsx needs to be returned all-in-one-shot, this can't be broken down into smaller pieces. I left the methods at the top in case I'm wrong and want to 
    // revisit in the future. For now, have to make do with a gigantic function to be able to build the jsx.
    const styleAndRenderJsx = ((mechUuid, sourceString, topLevelCssRowClassNames, itemLevelCssRowClassNames) => {
        const tagStartingPositionIndex = 0;
        const tagTypeIndex = 1;

        const findFirstStartTagAnyType = ((stringToSearch) => {
            let lowestIndex = [];

            // i = 1 because we're skipping the <bg_rgb> tag
            for (let i = 1; i < startTags.length; i++) {
                let startTagStartPos = stringToSearch.indexOf(startTags[i]);

                if ((lowestIndex[0] > startTagStartPos || lowestIndex.length === 0) && startTagStartPos > -1) {
                    lowestIndex[0] = startTagStartPos;
                    lowestIndex[1] = i;
                }
            }

            return (lowestIndex.length > 0) ? lowestIndex : -1;
        });

        const findMatchingEndTagOfSameType = ((stringToSearch, tagType) => {
            return [stringToSearch.indexOf(endTags[tagType]), tagType];
        });

        const getStringStartingIndexAfterFirstTag = ((stringWithStartTagAnywhereInside, tagStartingPosition, startTagType) => {
            if ([0, 1].includes(startTagType))
                return stringWithStartTagAnywhereInside.indexOf(")>") + tagStartingPosition + 2;
            else
                return tagStartingPosition + startTags[startTagType].length;
        });

        const getRgbValuesFromStartTag = ((stringStartingWithStartTag, startTagType) => {
            let startTagEndPos = stringStartingWithStartTag.indexOf(")>");
            stringStartingWithStartTag.substring(startTags[0].length, startTagEndPos);

            return stringStartingWithStartTag.substring(startTags[startTagType].length, startTagEndPos);
        });

        const constructNewJsxTagObject = ((tagType, tagProperties, nestedContentArr) => {
            return { tagType: tagType, tagProperties: tagProperties, nestedContentArr: nestedContentArr };
        });


        const injectBgRgbReplacements = ((stringToInject) => {
            let newString = stringToInject;
            let startTagStartPos = newString.indexOf(startTags[0]);
            let bgRgbValues = "";

            if (startTagStartPos > -1) {
                let stringFromStartTagStart = newString.substring(startTagStartPos);
                bgRgbValues = getRgbValuesFromStartTag(stringFromStartTagStart, 0)

                let stringStartingIndexAfterFirstTag = getStringStartingIndexAfterFirstTag(stringFromStartTagStart, startTagStartPos, 0);
                let stringAfterStartTag = stringFromStartTagStart.substring(stringStartingIndexAfterFirstTag);
                let endTagStartPos = stringAfterStartTag.indexOf(endTags[0]);
                newString = newString.substring(0, startTagStartPos) + stringAfterStartTag.substring(0, endTagStartPos);
            }

            return [newString, bgRgbValues];
        });

        // A more detailed explanation of what this function does can be found lower in the code in the main loop. This method does pretty much the exact
        // same thing, and can't be combined any further with the top-level loop. Since when rendering the jsx the child is unaware of anything in the parent, the same 
        // logic in the child needs to be in the parent. 1 set of duplication is unavoidable, unless there's a completely different approach to this.
        // Also, currentDepth is just to safeguard against infinite loops in case something goes wrong with the recursion
        const searchForNestedTags = ((stringToSearch, currentDepth) => {
            if (currentDepth > 20) {
                console.log("infinite loop detected during recursion, exiting...");
                return [false, null];
            }

            let infiniteLoopCounter = 0;
            let jsxArr = [];
            let jsxTagObject;

            let newString = stringToSearch;

            // let stringAfterFirstTag; - Keep for debugging
            let firstTagInfo = findFirstStartTagAnyType(newString);

            while (firstTagInfo[tagStartingPositionIndex] > -1) {
                let stringBeforeFirstTag = newString.substring(0, firstTagInfo[tagStartingPositionIndex]);

                if(stringBeforeFirstTag.length > 0)
                    jsxArr.push(stringBeforeFirstTag);

                let stringStartingIndexAfterFirstTag = getStringStartingIndexAfterFirstTag(newString, firstTagInfo[tagStartingPositionIndex], firstTagInfo[tagTypeIndex]);

                // Just here for debugging purposes
                //stringAfterFirstTag = newString.substring(stringStartingIndexAfterFirstTag);
                let matchingEndTagInfo = findMatchingEndTagOfSameType(newString, firstTagInfo[tagTypeIndex]);
                let stringBetweenMatchingTags = newString.substring(stringStartingIndexAfterFirstTag, matchingEndTagInfo[tagStartingPositionIndex]);

                let stringStartingIndexAfterMatchingEndTag = matchingEndTagInfo[tagStartingPositionIndex] + endTags[matchingEndTagInfo[tagTypeIndex]].length;

                // Just here for debugging purposes
                //let stringAfterMatchingEndTag = newString.substring(stringStartingIndexAfterMatchingEndTag);

                let [nestedContentFound, nestedJsxArr] = searchForNestedTags(stringBetweenMatchingTags, ++currentDepth);



                if (nestedContentFound) {
                    if (firstTagInfo[tagTypeIndex] === 1) {
                        let propertiesObject = { rgbValues: getRgbValuesFromStartTag(newString.substring(firstTagInfo[tagStartingPositionIndex]), firstTagInfo[tagTypeIndex]) };

                        jsxTagObject = constructNewJsxTagObject(firstTagInfo[tagTypeIndex], propertiesObject, nestedJsxArr);
                    }
                    else {
                        jsxTagObject = constructNewJsxTagObject(firstTagInfo[tagTypeIndex], null, nestedJsxArr);
                    }
                }

                else {
                    if (firstTagInfo[tagTypeIndex] === 1) {
                        let propertiesObject = { rgbValues: getRgbValuesFromStartTag(newString.substring(firstTagInfo[tagStartingPositionIndex]), firstTagInfo[tagTypeIndex]) };

                        jsxTagObject = constructNewJsxTagObject(firstTagInfo[tagTypeIndex], propertiesObject, null);
                    }

                    else {
                        jsxTagObject = constructNewJsxTagObject(firstTagInfo[tagTypeIndex], null, null);
                    }
                }

                //return [true, jsxTagObject];?????????????????????????????????????

                jsxArr.push(jsxTagObject);

                let stringIndexTracker = stringStartingIndexAfterMatchingEndTag;
                newString = newString.substring(stringIndexTracker);

                firstTagInfo = findFirstStartTagAnyType(newString);

                // There will only be a few iterations tops, if there's 20 then something went wrong
                if (infiniteLoopCounter++ > 20)
                    break;
            }

            // Process any strings left at the end (or if the source string was empty, it was stored as a blank row)
            if (newString.length > 0)
                jsxArr.push(newString);
            else if (sourceString.length === 0)
                jsxArr.push("");

            if (jsxArr.length > 0)
                return [true, jsxArr];

            else 
                return [false, null];
        });

        
        // ************************************************************** CODE STARTS HERE ****************************************************************
        let newString = sourceString;
        let bgRgbValues;
        // ************************************************** Start <bg_rgb( Replacements (startTags[0]) **************************************************
        [newString,bgRgbValues] = injectBgRgbReplacements(newString);
        // *************************************************** End <bg_rgb( Replacements (startTags[0]) ***************************************************





        // ************************************************** Start Remaining Replacements **************************************************
        let firstTagInfo = findFirstStartTagAnyType(newString);
        //let stringAfterFirstTag; - Keep for debugging
        //let stringAfterMatchingEndTag; - Keep for debugging

        let infiniteLoopCounter = 0;
        let jsxArr = [];
        let jsxTagObject;

        // General approach here at the top-level is to rotate object types to jsxArr. String, tag, string, tag, etc. 
        while (firstTagInfo[tagStartingPositionIndex] > -1) {
            let stringBeforeFirstTag = newString.substring(0, firstTagInfo[tagStartingPositionIndex]);
            let stringStartingIndexAfterFirstTag = getStringStartingIndexAfterFirstTag(newString, firstTagInfo[tagStartingPositionIndex], firstTagInfo[tagTypeIndex]);

            if (stringBeforeFirstTag.length > 0)
                jsxArr.push(stringBeforeFirstTag);

            let matchingEndTagInfo = findMatchingEndTagOfSameType(newString, firstTagInfo[tagTypeIndex]);
            let stringBetweenMatchingTags = newString.substring(stringStartingIndexAfterFirstTag, matchingEndTagInfo[tagStartingPositionIndex]);

            let stringStartingIndexAfterMatchingEndTag = matchingEndTagInfo[tagStartingPositionIndex] + endTags[matchingEndTagInfo[tagTypeIndex]].length;

            // Just for debugging
            //let stringAfterMatchingEndTag = newString.substring(stringStartingIndexAfterMatchingEndTag);

            // The whole recursion thing here is tricky. Go into the recursive function searchForNestedTags() further up and look for any other tags that exist
            // between this string's current tags. If any exist, drop down another level into that tag's tags, and so on. Once control hits the very bottom where 
            // there is nothing but plain text between a set of tags, the function will start to bubble up jsxTagObjects. Explanation for that below after the recursion call.
            // Also, 0 means nothing here, it's just to guard against infinite loops
            let [nestedContentFound, nestedJsxArr] = searchForNestedTags(stringBetweenMatchingTags, 0);

            // If any nested tags were found, take their content object (a regular javascript array object), and populate here at the parent level (this will be on the
            // nestedContentArr property). For every recursion call, if nested tags are found, an object will be stored at that level with its children just like here. 
            if (nestedContentFound) {
                // If the jsxTagObject at this level is a <txt_rgb>, set its RGB values to a propertiesObject. (Do NOT do this down at the child's level)
                if (firstTagInfo[tagTypeIndex] === 1) {
                    let propertiesObject = { rgbValues: getRgbValuesFromStartTag(newString.substring(firstTagInfo[tagStartingPositionIndex]), firstTagInfo[tagTypeIndex]) };

                    jsxTagObject = constructNewJsxTagObject(firstTagInfo[tagTypeIndex], propertiesObject, nestedJsxArr);
                }
                // Otherwise save the object the same way, just without the rgb properties
                else {
                    jsxTagObject = constructNewJsxTagObject(firstTagInfo[tagTypeIndex], null, nestedJsxArr);
                }
            }

            // If no nested tags were found while here at the top-level, it's just a top-level tag with plain text inside. Create a jsxTagObject containg just a 
            // string and the type of the top-level tag
            else {
                if (firstTagInfo[tagTypeIndex] === 1) {
                    let propertiesObject = { rgbValues: getRgbValuesFromStartTag(newString.substring(firstTagInfo[tagStartingPositionIndex]), firstTagInfo[tagTypeIndex]) };

                    jsxTagObject = constructNewJsxTagObject(firstTagInfo[tagTypeIndex], propertiesObject, null);
                }

                else {
                    jsxTagObject = constructNewJsxTagObject(firstTagInfo[tagTypeIndex], null, null);
                }
            }

            jsxArr.push(jsxTagObject);

            let stringIndexTracker = stringStartingIndexAfterMatchingEndTag;
            newString = newString.substring(stringIndexTracker);

            firstTagInfo = findFirstStartTagAnyType(newString);

            // There will only be a few iterations tops, if there's 20 then something went wrong
            if (infiniteLoopCounter++ > 20)
                break;
        }

        // Process any strings left at the end (or if the source string was empty, it was stored as a blank row)
        if (newString.length > 0)
            jsxArr.push(newString);
        else if (sourceString.length === 0)
            jsxArr.push("");

        return renderJsx(jsxArr, topLevelCssRowClassNames, itemLevelCssRowClassNames, bgRgbValues, mechUuid);
    });
    
    const renderJsx = ((jsxArr, topLevelCssRowClassNames, itemLevelCssRowClassNames, bgRgbValues, mechUuid) => {

        // currentDepth is only to guard against infinite loops
        const renderJsxForTagType = ((entry, mechUuid, mechJsxCounter, currentDepth) => {
            if (currentDepth > 20) {
                console.log("infinite loop detected during recursion, exiting...");
                return null;
            }

            let reactKey = mechUuid + "-" + mechJsxCounter;
            let nestedContent;

            if (entry.nestedContentArr !== undefined && entry.nestedContentArr !== null) {
                nestedContent = entry.nestedContentArr.map((nestedEntry) => {
                    if (!nestedEntry.hasOwnProperty("tagType"))
                        return nestedEntry;
                    else
                        return renderJsxForTagType(nestedEntry, mechUuid, ++mechJsxCounter, ++currentDepth);
                });
            }

            else
                nestedContent = "***(Possible Bug Rendering This Row of the Guide)***";

            switch (entry.tagType) {
                case 1:
                    return (
                        <span key={reactKey + "-txtRgbChild"} style={{ color: "Rgb(" + entry.tagProperties.rgbValues + ")" }}>
                            {nestedContent}
                        </span>
                    );
                case 2:
                    return (
                        <span key={reactKey}>
                            <span key={reactKey + "-boldChild"} style={{ fontWeight: "bold" }}>
                                {nestedContent}
                            </span>
                        </span>
                    );
                case 3:
                    return (
                        <span key={reactKey}>
                            <span key={reactKey + "-underlineChild"} style={{ textDecoration: "underline" }}>
                                {nestedContent}
                            </span>
                        </span>
                    );
                default:
                    return (
                        <span key={reactKey + "-errorChild"}>
                            {nestedContent}
                        </span>
                    )
            }
        });


        // This is only here for unique identifier purposes for react. Each mechUuid should be unique per row, so just tack on the counter
        // after each iteration and it should be unique
        let mechJsxCounter = 0;

        let content = "***(Possible Bug Rendering This Row of the Guide)***";

        if (jsxArr !== undefined && jsxArr !== null) {
            content = jsxArr.map((entry) => {
                if (entry.hasOwnProperty("tagType")) {
                    return renderJsxForTagType(entry, mechUuid, ++mechJsxCounter, 0);
                }

                else {
                    let reactKey = mechUuid + "-" + ++mechJsxCounter;

                    return ((entry.length > 0)
                        ?
                        <span key={reactKey}>{entry}</span>
                        :
                        <span key={reactKey}>&nbsp;</span>
                    );
                }
            })
        }

        let jsx = (bgRgbValues.length > 0)
            ?
            <>
                <div className={topLevelCssRowClassNames.join(" ")} style={{ backgroundColor: "rgb(" + bgRgbValues + ")" }}>
                    <div className={itemLevelCssRowClassNames.join(" ")}>
                        {content}
                    </div>
                </div>
            </>
            :
            <>
                <div className={topLevelCssRowClassNames.join(" ")}>
                    <div className={itemLevelCssRowClassNames.join(" ")}>
                        {content}
                    </div>
                </div>
            </>;

        return (
            <>
                {jsx}
            </>
        );
    });

    return {
        injectStyling: injectStyling
    }
});

export default DungeonGuideResultsStyler;

import * as React from 'react';
import { useEffect, useRef } from 'react';

import css from './DungeonGuideSearchResults.module.css';

const DungeonGuideSearchResults = (componentProps) => {
    const searchResultsContainerRef = useRef(null);
    let currentTimeout = 0;





    const onSearchResultSubmit = (async (uuid) => {
        let jsonModel = { dungeonUuid: uuid, useBriefVersion: componentProps.configSettings.BriefGuideVersions }

        let postUrl = process.env.REACT_APP_DUNGEON_GUIDE_API_URL;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...jsonModel })
        };

        await fetch(postUrl, requestOptions)
            .then(response => {
                if (response.ok)
                    return response.json();
            })
            .then(jsonData => {
                componentProps.parentUpdateDungeonGuideResultsState(jsonData);
            })
            .catch((response) => {
                alert("(2) An error has occurred trying to retrieve the guide. Try searching on a different term and if the problem persists feel free to contact me.")
            });
    });

    const handleSearchResultOnClick = ((event) => {
        clearTimeout(currentTimeout);

        currentTimeout = setTimeout(() => {
            onSearchResultSubmit(event.target.id);
        }, 2000);
    });

    const MaskDungeonNameString = ((props) => {
        let newDungeonName = "";

        if (componentProps.configSettings.NoDungeonSpoilers) {
            let originalSearchRequestValueStartingPosition = props.dungeonName.toLowerCase().indexOf(props.originalSearchRequestValue.toLowerCase());

            for (let x = 0; x < originalSearchRequestValueStartingPosition; x++)
                newDungeonName += "?";

            let resumeReplacementStartingPosition = originalSearchRequestValueStartingPosition + props.originalSearchRequestValue.length + 1;
            newDungeonName += props.dungeonName.substring(originalSearchRequestValueStartingPosition, resumeReplacementStartingPosition - 1);

            // length variable vs an index variable, hence the +1
            let lengthToReplaceRemaining = (props.dungeonName.length + 1) - resumeReplacementStartingPosition;

            for (let x = 0; x < lengthToReplaceRemaining; x++)
                newDungeonName += "?";
        }

        else
            newDungeonName = props.dungeonName;

        return newDungeonName;
    });

    const RenderOneSearchResult = ((props) => {
        let newDungeonName = (props.performDungeonNameMask) ? MaskDungeonNameString(props) : props.dungeonName;

        return (
            <>
                <div id={props.dungeonUuid} className={props.className} onClick={props.onClick}>
                    {newDungeonName}
                </div>
            </>
        );
    });

    const RenderDungeonGuideSearchResults = (() => {
        let jsonEntries = componentProps.searchResultsJson.dungeonNameSearchResults;

        if (jsonEntries === undefined || jsonEntries === null)
            return;

        let resultDivs = [];

        // There's no index in for-in like with for-of
        let rowNumber = 0;

        for (let key in jsonEntries) {
            ++rowNumber;
            let entry = jsonEntries[key];

            let performDungeonNameMask = (jsonEntries.length === 1) ? false : true;

            resultDivs.push(<RenderOneSearchResult key={entry.uuid} rowNumber={rowNumber} dungeonUuid={entry.uuid} dungeonName={entry.name} className={css["search-result-container"]} onClick={handleSearchResultOnClick} originalSearchRequestValue={componentProps.searchResultsJson.originalSearchRequestValue} performDungeonNameMask={performDungeonNameMask} />);
        }

        if (jsonEntries.length === 0) {
            resultDivs.push(<RenderOneSearchResult key={"0"} rowNumber={-1} dungeonName={"(No Results Found...)"} className={css["dummy-search-result-container"]} onClick={() => { return false; }} performDungeonNameMask={false} />);
        }

        // The 11th entry, while populated, is just a placeholder to put the dummy message in below. We're showing 10 results.
        else if (jsonEntries.length === 11) {
            resultDivs.pop();
            resultDivs.push(<RenderOneSearchResult key={"0"} rowNumber={11} dungeonName={"(More Results Exist...)"} className={css["dummy-search-result-container"]} onClick={() => { return false; }} performDungeonNameMask={false} />);
        }

        return (
            <>
                <div ref={searchResultsContainerRef} id={css["search-results-container"]}>
                    {resultDivs}
                </div>
            </>
        );
    });


    // Search results don't have the X to close like a pop-up, plus they're not available at render like the others, so 
    // better off just giving it its own useEffect method
    useEffect(() => {
        function handleClickOutsideSearchResults(event) {
            if (!componentProps.showSearchResults)
                return;

            if  (
                    (searchResultsContainerRef.current && !searchResultsContainerRef.current.contains(event.target))
                    &&
                    (event.target.id !== componentProps.searchTextboxCssId)
                ) {
                componentProps.parentUpdateShowSearchResultsState(false);
                return;
            }
        }

        document.addEventListener("mousedown", handleClickOutsideSearchResults);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideSearchResults);
        };
    }, [componentProps]);





    return (
        <>
            {componentProps.showSearchResults && (
                <RenderDungeonGuideSearchResults />
            )}
        </>
    );
};

export default DungeonGuideSearchResults;
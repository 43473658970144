import css from './NavMenu.module.css';
import * as React from 'react';
import { NavLink } from "react-router-dom";

import nexzNexusLogo64 from '../static/images/nexzNexusLogo64.png';


// Note: CSS code at this level is in index.scss, the rest is in the components themselves

const NavMenu = (props) => {
    const RenderLogoAndSiteNameContainer = () => {
        return (
            <>
                <NavLink to="/" id={css["logo-and-site-name-container-anchor"]}>
                    <div id={css["logo-and-site-name-container"]}>
                        <div id={css["logo-and-site-name-flex-container"]}>
                            <img id={css["top-left-logo-image"]} src={nexzNexusLogo64} alt="Top Left Logo" /><span id={css["top-left-site-name"]}>Nex'z Nexus</span>
                        </div>
                    </div>
                </NavLink>
            </>
        );
    }

    const RenderNavMenuItem = (props) => {
        return (
            <>
                <NavLink to={props.to} className={css["nav-menu-item-anchor"]}>
                    <div id={props.id} className={css["nav-menu-item"]}>{props.name}</div>
                </NavLink>
            </>
        );
    }

    // Like an OnLoad() (but does NOT account for things that might still be loading like images), React will run this only the 1st time a component is rendered, due to the empty dependency array
    /*useEffect(() => {
        homeTextSubsectionContainersRef.current = Array.from(document.getElementsByClassName("home-text-subsection-container"));

        document.getElementById("foreground").focus();
    }, []);*/

    return (
        <>
            <div id={css["header"]}>
                <RenderLogoAndSiteNameContainer />

                <div id={css["nav-menu-container"]}>
                    <nav id={css["nav-menu-items-flex-container"]}>
                        {/* Note that when using css modules, the id MUST exist in the scss file or the id won't get generated here. There is a fake white-space */}
                        {/* entry to ensure it gets generated here */}
                        <RenderNavMenuItem to="/ff14/dungeon-guides" id={css["ff14-dungeon-guides-nav-menu-item"]} name="FF14 Dungeon Guides" />
                    </nav>
                </div>
            </div>
        </>
    );
};

export default NavMenu;
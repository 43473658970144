import * as React from 'react';
import { useEffect, useRef } from 'react';

import pucCss from './PopUpContainers.module.css';
import css from './ConfigPopUp.module.css';

import ConfigPopUpForm from './ConfigPopUpForm'





const ConfigPopUp = (componentProps) => {

    const configPopUpContainerRef = useRef(null);
    const configPopUpContainerXIconRef = useRef(null);





    const RenderConfigPopUp = (() => {
        return (
            <>
                <div ref={configPopUpContainerRef} id={css["config-pop-up-container"]} className={`${pucCss["pop-up-containers"]} ${componentProps.className}`}>
                    <div ref={configPopUpContainerXIconRef} id={css["config-pop-up-container-x-icon"]} className={pucCss["pop-up-container-x-icons"]}>
                        X
                    </div>

                    <div id={css["config-pop-up-container-header"]} className={pucCss["pop-up-container-headers"]}>
                        Options
                    </div>

                    <div id={css["config-pop-up-container-header-line-2"]}>
                        (Will be saved for future visits)
                    </div>

                    <div className={pucCss["pop-up-container-content"]}>
                        <ConfigPopUpForm parentUpdateConfigSettingsState={componentProps.parentUpdateConfigSettingsState} />
                    </div>
                </div>
            </>
        );
    });

    useEffect(() => {
        // Give animations a second to finish, then reset the state back to the defaults so they don't keep doing it on re-renders
        if (!componentProps.showConfigPopUp && componentProps.className === pucCss["close-pop-up"]) {
            setTimeout(() => {
                componentProps.parentUpdateShowConfigPopUpState(false, pucCss["initial-pop-up-state-hidden"]);
            }, 500);

            // return here but not in the else if below because the event handler function still needs to be generated
            return;
        }

        else if (componentProps.showConfigPopUp && componentProps.className === pucCss["show-pop-up"]) {
            setTimeout(() => {
                componentProps.parentUpdateShowConfigPopUpState(true, pucCss["initial-pop-up-state-shown"]);
            }, 500);
        }

        function handleClickOutsidePopUp(event) {
            if (!componentProps.showConfigPopUp)
                return;

            if (
                    (configPopUpContainerRef.current && !configPopUpContainerRef.current.contains(event.target)) ||
                    (configPopUpContainerXIconRef.current && configPopUpContainerXIconRef.current.contains(event.target))
            ) {
                componentProps.parentUpdateShowConfigPopUpState(false, pucCss["close-pop-up"]);
                return;
            }
        }

        document.addEventListener("mousedown", handleClickOutsidePopUp);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsidePopUp);
        };
    }, [componentProps]);





    return (
        <>
            <RenderConfigPopUp />
        </>
    );
};

export default ConfigPopUp;
import css from './FF14Index.module.css';
import { NavLink } from 'react-router-dom';

const FF14Index = (componentProps) => {
    return (
        <>
            <div id={css.header}>
                Future FFXIV Index Page (not in use, just a placeholder)
            </div>

            <NavLink to="/ff14/dungeon-guide">To Dungeon Guide Page</NavLink>
        </>
    );
}

export default FF14Index;
import * as React from 'react';
import { useEffect, useRef } from 'react';

import pucCss from './PopUpContainers.module.css';
import css from './InfoPopUp.module.css';
import Emoji from './Emoji.js';




const InfoPopUp = (componentProps) => {
    const infoPopUpContainerRef = useRef(null);
    const infoPopUpContainerXIconRef = useRef(null);





    const RenderInfoPopUp = (() => {
        return (
            <>
                <div ref={infoPopUpContainerRef} id={css["info-pop-up-container"]} className={`${pucCss["pop-up-containers"]} ${componentProps.className}`}>
                    <div ref={infoPopUpContainerXIconRef} id={css["info-pop-up-container-x-icon"]} className={pucCss["pop-up-container-x-icons"]}>
                        X
                    </div>

                    <div id={css["info-pop-up-container-disclaimer"]}>
                        <span style={{ fontWeight: "bold" }}>Disclaimer:</span> The guides only cover <span style={{ fontWeight: "bold", color: "rgb(255,0,0)" }}>ROULETTES</span> and
                        are targeted towards newer players as a quick way to give them a basic run-down of what each dungeon will require of them. It's
                        also intended for people like me who can't remember half of these places once a few days has passed <Emoji label="sheep" symbol="🥴" />. There's no extreme or
                        savage content here. Perhaps down the road...
                    </div>

                    <div id={css["info-pop-up-container-header"]} className={pucCss["pop-up-container-headers"]}>
                        Info/Legend
                    </div>

                    <div className={pucCss["pop-up-container-content"]}>
                        <div id={css["info-pop-up-grid"]}>
                            {/******************************************************************************************************************************/}
                            <div className={css["info-pop-up-grid-row-label"]}>
                                Verbose:
                            </div>

                            <div>
                                Long-winded & includes basic mechs; only use if stuck on a specific mech
                            </div>
                            {/******************************************************************************************************************************/}
                            <div className={css["info-pop-up-grid-row-label"]}>
                                Brief:
                            </div>

                            <div>
                                Short & sweet; only shows the important stuff
                            </div>
                            {/******************************************************************************************************************************/}
                            <div className={css["info-pop-up-grid-row-label"]}>
                                Tank Mechs:
                            </div>

                            <div className={[css["info-pop-up-grid-row-color-bar"], css["tank-mech-color"]].join(" ")} />
                            {/******************************************************************************************************************************/}
                            <div className={css["info-pop-up-grid-row-label"]}>
                                Healer Mechs:
                            </div>

                            <div className={[css["info-pop-up-grid-row-color-bar"], css["healer-mech-color"]].join(" ")} />
                            {/******************************************************************************************************************************/}
                            <div className={css["info-pop-up-grid-row-label"]}>
                                Important!:
                            </div>

                            <div id={css["info-pop-up-grid-row-important"]}>
                                Red Text
                            </div>
                            {/******************************************************************************************************************************/}
                        </div>

                        <hr id={css["info-pop-up-fake-hr"]} />

                        <div id={css["info-pop-up-container-notes"]}>
                            <span style={{ fontWeight: "bold" }}>
                                Regarding the brief version, the mechs <span style={{ color: "rgb(255,0,0)" }}>NOT</span> included are:
                            </span>

                            <div className={css["info-pop-up-container-notes-omitted-mechs"]}>Standard AOEs/Markers that only do a standard telegraphed AOE</div>
                            <div className={css["info-pop-up-container-notes-omitted-mechs"]}>Standard Proximity AOEs (Prox bombs)</div>
                            <div className={css["info-pop-up-container-notes-omitted-mechs"]}>Triple White Arrow Markers (Spread-out/Get away from raid)</div>
                            <div className={css["info-pop-up-container-notes-omitted-mechs"]}>Red "Dorito" Triangle Markers (Stack-up w/ other doritos)</div>
                            <div className={css["info-pop-up-container-notes-omitted-mechs"]}>1-Person Meteor Circles (At least 1 person stands in each circle)</div>
                            <div className={css["info-pop-up-container-notes-omitted-mechs"]}>Thin Ice/Frozen Ground (Players slide around)</div>
                            <div className={css["info-pop-up-container-notes-omitted-mechs"]}>Slow-moving Rockets (Get behind a set after it passes)</div>
                            <div className={css["info-pop-up-container-notes-omitted-mechs"]}>Telegraphed Knock-backs (Arrows show the source of the KB to run to)</div>
                        </div>

                        <hr id={css["info-pop-up-fake-hr"]} />

                        <div id={css["info-pop-up-container-credits"]}>
                            <span style={{ fontWeight: "bold" }}>
                                Credit to Mizzteq &amp; Mr. Happy as some content came from their guides which can be found at:
                            </span>
                            <div id={css["info-pop-up-container-credit-row-container"]}>
                                <div className={css["info-pop-up-container-credits-row"]}><a href="https://www.youtube.com/c/MTQcapture">Mizzteq's YouTube Channel</a></div>
                                <div className={css["info-pop-up-container-credits-row"]}><a href="https://www.youtube.com/c/Xehanort1227">Mr. Happy's</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    });

    useEffect(() => {
        // Give animations a sec to finish, then reset the state back to the defaults so they don't keep doing it on re-renders
        if (!componentProps.showInfoPopUp && componentProps.className === pucCss["close-pop-up"]) {
            setTimeout(() => {
                componentProps.parentUpdateShowInfoPopUpState(false, pucCss["initial-pop-up-state-hidden"]);
            }, 500);

            // return here but not in the else if below because the event handler function still needs to be generated
            return;
        }

        else if (componentProps.showInfoPopUp && componentProps.className === pucCss["show-pop-up"]) {
            setTimeout(() => {
                componentProps.parentUpdateShowInfoPopUpState(true, pucCss["initial-pop-up-state-shown"]);
            }, 500);
        }

        function handleClickOutsidePopUp(event) {
            if (!componentProps.showInfoPopUp)
                return;

            if (
                (infoPopUpContainerRef.current && !infoPopUpContainerRef.current.contains(event.target)) ||
                (infoPopUpContainerXIconRef.current && infoPopUpContainerXIconRef.current.contains(event.target))
            ) {
                componentProps.parentUpdateShowInfoPopUpState(false, pucCss["close-pop-up"]);
                return;
            }
        }

        document.addEventListener("mousedown", handleClickOutsidePopUp);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsidePopUp);
        };
    }, [componentProps]);

    return (
        <>
            <RenderInfoPopUp />
        </>
    );
};

export default InfoPopUp;
function GetConfigSettings () {
    let localStorageConfigFormData = JSON.parse(window.localStorage.getItem("configFormData"));

    // Important defaults. Unless the exact item can be found in local storage, stick with these
    let configSettings = { BriefGuideVersions: true, NoDungeonSpoilers: true, NoBossSpoilers: true, ShowTankMechs: true, ShowHealerMechs: true, ShowPartyMechs: true };

    // Normally would ternary but want the above part to be easily readable
    if (localStorageConfigFormData?.briefGuideVersionsCheckbox !== undefined)
        configSettings.BriefGuideVersions = localStorageConfigFormData.briefGuideVersionsCheckbox;

    if (localStorageConfigFormData?.noDungeonSpoilersCheckbox !== undefined)
        configSettings.NoDungeonSpoilers = localStorageConfigFormData.noDungeonSpoilersCheckbox;

    if (localStorageConfigFormData?.noBossSpoilersCheckbox !== undefined)
        configSettings.NoBossSpoilers = localStorageConfigFormData.noBossSpoilersCheckbox;

    if (localStorageConfigFormData?.showTankMechsCheckbox !== undefined)
        configSettings.ShowTankMechs = localStorageConfigFormData.showTankMechsCheckbox;

    if (localStorageConfigFormData?.showHealerMechsCheckbox !== undefined)
        configSettings.ShowHealerMechs = localStorageConfigFormData.showHealerMechsCheckbox;

    if (localStorageConfigFormData?.showPartyMechsCheckbox !== undefined)
        configSettings.ShowPartyMechs = localStorageConfigFormData.showPartyMechsCheckbox;

    return configSettings;
}

export default GetConfigSettings;

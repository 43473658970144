import * as React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import css from './DungeonGuideNavMenu.module.css';
import pucCss from './PopUpContainers.module.css';

import nexzNexusLogo64 from '../../static/images/nexzNexusLogo64.png';
import statusIcon64 from '../../static/images/statusIcon64.png';
import gearIcon64 from '../../static/images/gearIcon64.png';

import DungeonGuideSearchForm from './DungeonGuideSearchForm';
import DungeonGuideSearchResults from './DungeonGuideSearchResults';
import InfoPopUp from './InfoPopUp';
import ConfigPopUp from './ConfigPopUp';





const DungeonGuideNavMenu = (props) => {
    const [showConfigPopUp, setShowConfigPopUp] = useState(false);
    const [configPopUpClassName, setConfigPopUpClassName] = useState(pucCss["initial-pop-up-state-hidden"]);
    const [showInfoPopUp, setShowInfoPopUp] = useState(false);
    const [infoPopUpClassName, setInfoPopUpClassName] = useState(pucCss["initial-pop-up-state-hidden"]);
    const [searchResultsJson, setSearchResultsJson] = useState(null);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [searchTextboxCssId, setSearchTextboxCssId] = useState(null);




    // Yes, these next two are duplicated but I don't care, the naming is clear this way
    const showInfoPopUpStateUpdaterForChild = ((value, cssClassName) => {
        setShowInfoPopUp(value);
        setInfoPopUpClassName(cssClassName);
    });

    const handleInfoIconClicked = (() => {
        setShowInfoPopUp(true);
        setInfoPopUpClassName(pucCss["show-pop-up"]);
    });

    const showConfigPopUpStateUpdaterForChild = ((value, cssClassName) => {
        setShowConfigPopUp(value);
        setConfigPopUpClassName(cssClassName);
    });

    const handleGearIconClicked = (() => {
        setShowConfigPopUp(true);
        setConfigPopUpClassName(pucCss["show-pop-up"]);
    });

    const showSearchResultsStateUpdaterForChild = ((value) => {
        setShowSearchResults(value);
    });

    const searchResultStatesUpdaterForChild = ((cssTextboxId, jsonData) => {
        setSearchTextboxCssId(cssTextboxId);
        setSearchResultsJson(jsonData);
        setShowSearchResults(true);
    });

    const dungeonGuideResultsStateUpdaterForChild = ((jsonData) => {
        props.parentUpdateDungeonGuideResultsState(jsonData);
    });

    const searchTextboxCssIdStateUpdaterForChild = ((value) => {
        setSearchTextboxCssId(value)
    });



    const RenderLogoAndSiteNameContainer = (() => {
        return (
            <>
                <NavLink to="/" id={css["logo-and-site-name-container-anchor"]}>
                    <div id={css["logo-and-site-name-container"]}>
                        <div id={css["logo-and-site-name-flex-container"]}>
                            <img id={css["top-left-logo-image"]} src={nexzNexusLogo64} alt="Top Left Logo" /><div id={css["top-left-site-name"]}>Nex'z Nexus</div>
                        </div>
                    </div>
                </NavLink>
            </>
        );
    });

    const RenderNavIcons = ((props) => {
        return (
            <>
                <img id={props.id} src={props.src} className={props.className} onClick={props.onClick} alt={"Nav Icon"} />
            </>
        );
    });


    useEffect(() => {
        // Once things are rendered (meaning animations have fired) reset the className states for the pop-up windows if they're not visible
        //if (!showConfigPopUp) 
            //setConfigPopUpClassName(pucCss["initial-pop-up-state"]);

        //if (!showInfoPopUp)
            //setInfoPopUpClassName(pucCss["initial-pop-up-state"]);
    },[showConfigPopUp, showInfoPopUp]);


    return (
        <>
            <div id={css["header"]}>
                <RenderLogoAndSiteNameContainer />

                <div id={css["nav-container"]}>
                    <nav id={css["nav-flex-container"]}>
                        <RenderNavIcons id={css["info-nav-icon"]} className={css["nav-icons"]} src={statusIcon64} onClick={() => handleInfoIconClicked()} />
                        <RenderNavIcons id={css["gear-nav-icon"]} className={css["nav-icons"]} src={gearIcon64} onClick={() => handleGearIconClicked()} />

                        <div id={css["search-container"]}>
                            <DungeonGuideSearchForm configSettings={props.configSettings}  parentUpdateSearchResultStates={searchResultStatesUpdaterForChild} parentUpdateSearchTextboxCssId={searchTextboxCssIdStateUpdaterForChild} />
                            <DungeonGuideSearchResults configSettings={props.configSettings} showSearchResults={showSearchResults} searchResultsJson={searchResultsJson} searchTextboxCssId={searchTextboxCssId} parentUpdateShowSearchResultsState={showSearchResultsStateUpdaterForChild} parentUpdateDungeonGuideResultsState={dungeonGuideResultsStateUpdaterForChild} />
                        </div>
                    </nav>
                </div>
            </div>

            <ConfigPopUp showConfigPopUp={showConfigPopUp} className={configPopUpClassName} parentUpdateShowConfigPopUpState={showConfigPopUpStateUpdaterForChild} parentUpdateConfigSettingsState={props.parentUpdateConfigSettingsState} />

            <InfoPopUp showInfoPopUp={showInfoPopUp} className={infoPopUpClassName} parentUpdateShowInfoPopUpState={showInfoPopUpStateUpdaterForChild} />

            {((showConfigPopUp) || showInfoPopUp) && (
                <>
                    <div id={pucCss["div-to-gray-out-entire-page"]} />
                </>
            )}
        </>
    );
};

export default DungeonGuideNavMenu;
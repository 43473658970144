import css from './NnIndex.module.css';
import * as React from 'react';

import NavMenu from './NavMenu'
import nexusImage from '../static/images/nexus.gif';
import reaverImage from '../static/images/reaver.png';

import useImagesLoaded from "../Hooks/useImagesLoaded";


const NnIndex = (props) => {
    const fireReaverAmmo = () => {
        let thirdReaverAmmoElement = document.getElementById(css["reaver-ammo-3"]);
        let rootElement = document.documentElement;

        rootElement.style.setProperty("--reaver-ammo-ending-top", (thirdReaverAmmoElement.getBoundingClientRect().top - 20) * -1 + "px");
        rootElement.style.setProperty("--reaver-ammo-ending-right", (window.innerWidth - thirdReaverAmmoElement.getBoundingClientRect().right - 75) * -1 + "px");
    }

    useImagesLoaded(() => {
        fireReaverAmmo();
    }, []);

    // Like an OnLoad() (but does NOT account for things that might still be loading like images), React will run this only the 1st time a component is rendered, due to the empty dependency array
    //useEffect(() => {
    //}, []);

    return (
        <>
            <div id={css["nn-index-page-identifier"]}>
                <NavMenu />
            </div>

            <div id={css["spacer-for-nav-menu"]} />

            <div id={css["page-container"]}>
                <div id={css["page-header"]}>
                    Welcome to Nex'z Nexus!
                </div>

                <div id={css["page-header-line-2"]}>
                    (Nex Violentus@Behemoth)
                </div>

                <div id={css["images"]}>
                    <img id={css["nexus-image"]} src={nexusImage} alt="nexus" />

                    <div id={css["reaver-image-div"]}>
                        <img id={css["reaver-image"]} src={reaverImage} alt="reaver" />
                        <div id={css["reaver-ammo-1"]} className={css["reaver-ammo"]} />
                        <div id={css["reaver-ammo-2"]} className={css["reaver-ammo"]} />
                        <div id={css["reaver-ammo-3"]} className={css["reaver-ammo"]} />
                    </div>
                </div>

                <div id={css["page-content"]}>
                    One day, probably when Zerg and Protoss walk the Earth, there might be more to this page. Until then, let's face it &mdash; you're just here for the <span style={{ color: "red", fontSize: "50px" }}>GUIDES...</span>
                </div>
            </div>
        </>
    );
};

export default NnIndex;
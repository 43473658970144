import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import css from './DungeonGuideSearchForm.module.css';



const DungeonGuideSearchForm = (componentProps) => {
    const { register, handleSubmit } = useForm();
    let currentTimeout = 0;
    let allowFocusEventToFire = useRef(true);

    const onSearchFormSubmit = (async (data) => {
        let jsonModel = { dungeonGuidesSearchTextbox: data.dungeonGuidesSearchTextbox };
        jsonModel.useBriefVersion = componentProps.configSettings.BriefGuideVersions

        let postUrl = process.env.REACT_APP_DUNGEON_NAMES_API_URL;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...jsonModel })
        };

        await fetch(postUrl, requestOptions)
            .then(response => {
                if (response.ok)
                    return response.json();
            })
            .then(jsonData => {
                componentProps.parentUpdateSearchResultStates(css["search-textbox"], jsonData);
            })
            .catch((response) => {
                alert("(2) An error has occurred trying to retrieve the guide. Try searching on a different term and if the problem persists feel free to contact me.")
            });
    });

    const handleDefaultFormSubmission = ((e) => {
        e.preventDefault();
    });

    const handleSearchTextboxOnKeyUp = (() => {
        clearTimeout(currentTimeout);

        currentTimeout = setTimeout(() => {
            handleSubmit(onSearchFormSubmit)();
        }, 2000);
    });

    const handleSearchTextboxOnFocus = (() => {
        if (allowFocusEventToFire.current)
            handleSearchTextboxOnKeyUp();
    });

    // The search-textbox is here, but the dungeon results component needs it, which is a child of this parent. So it gets set here, passes back up to the parent on
    // render, then the parent passes it back down to the results component via componentProps.
    useEffect(() => {
        allowFocusEventToFire.current = false;
        document.getElementById(css["search-textbox"]).focus();
        allowFocusEventToFire.current = true;
    },[]);





    return (
        <>
            <div id={css["search-textbox-container"]}>
                <form id="search-form" onSubmit={handleDefaultFormSubmission}>
                    <input {...register("dungeonGuidesSearchTextbox")} id={css["search-textbox"]} onKeyUp={handleSearchTextboxOnKeyUp} onFocus={handleSearchTextboxOnFocus} placeholder="Dungeon Name" />
                </form>
            </div>
        </>
    );
};

export default DungeonGuideSearchForm;
//import 'bootstrap/dist/css/bootstrap.css';

import { createRoot } from 'react-dom/client';
//import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';
import NnIndex from './components/NnIndex';
import FF14Index from './components/FF14/FF14Index';
import FF14DungeonGuide from './components/FF14/DungeonGuide';

// For pre-loading
//import './static/fonts/squadaOne/squada-one-v12-latin-regular.woff2';
//import './static/fonts/squadaOne/squada-one-v12-latin-regular.woff';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();





let routes;

//if (window.location.host.split(".")[0] === "localhost:44444") {
if (window.location.host.split(".")[0] === "ff14") {
    routes = (
        <>
            <Route path="/dungeon-guides" element={<FF14DungeonGuide />} />
            <Route path="/" element={<FF14Index />} />
        </>
    );
}

else {
    routes = (
        <>
            <Route path="/ff14/dungeon-guides" element={<FF14DungeonGuide />} />
            <Route path="/ff14" element={<FF14Index />} />
            {/*<Route path="/" element={<NnIndex />} />*/}
            {/*The below is just temporary for testing */}
            <Route path="/" element={<NnIndex />} />
        </>
    );
}

const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript

root.render(
    <BrowserRouter basename={baseUrl}>
        <Routes>
            {routes}
        </Routes>
    </BrowserRouter>,
);
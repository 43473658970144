import { React, useState } from 'react';

import DungeonGuideNavMenu from './DungeonGuideNavMenu'
import DungeonGuideResultsStyler from './DungeonGuideResultsStyler'
import GetConfigSettings from './GetConfigSettings'

import css from './DungeonGuide.module.css';

const FF14DungeonGuide = (props) => {
    const [ConfigSettings, setConfigSettings] = useState(GetConfigSettings());
    const [dungeonGuideResultsJson, setDungeonGuideResultsJson] = useState(false);

    const dungeonGuideResultsStateUpdaterForChild = ((jsonData) => {
        setDungeonGuideResultsJson(jsonData);
    });

    const configSettingsStateUpdaterForChild = ((configSettings) => {
        setConfigSettings(configSettings);
    });


    // Note that the row container has to be generated in the DungeonGuideResultsStyler() call below for the background to take up the full width of the screen properly
    const RenderDungeonGuideRow = ((props) => {
        return ((props.bossName)
            ?
            <>
                <div className={props.className}>
                    {props.bossName}
                </div>

                {props.description}
            </>
            :
            <>
                {props.description}
            </>
        );
    });

    // Note that at the point this method is hit, it will only be mech entries, not bosses or trash as those were rendered outside already
    const shouldRowBeHidden = ((currentIndex) => {
        let lastIndexForJson = Object.keys(dungeonGuideResultsJson).length - 1;

        // Iff the the next row is a boss or trash entry, we want to retain the white-space entry before it
        let nextRowIsBossOrTrashEntry = ((currentIndex !== lastIndexForJson) && (dungeonGuideResultsJson[currentIndex + 1].bossName !== dungeonGuideResultsJson[currentIndex].bossName));

        if (nextRowIsBossOrTrashEntry)
            return false;

        // The main user settings come into play here
        if (
                    (ConfigSettings.ShowTankMechs && dungeonGuideResultsJson[currentIndex].isForTank)
                ||  (ConfigSettings.ShowHealerMechs && dungeonGuideResultsJson[currentIndex].isForHealer)
                ||  (ConfigSettings.ShowPartyMechs && dungeonGuideResultsJson[currentIndex].isForParty)
           )
            return false;

        // If it made it this far, then hide the row
        return true;
    });

    const RenderDungeonNotes = (() => {
        let reactKey = dungeonGuideResultsJson[0].dungeonUuid + "-notes";
        let notesArr = dungeonGuideResultsJson[0].dungeonNotes.split("<br>").filter(f => f.length > 0);

        return (
            <div id={dungeonGuideResultsJson[0].dungeonUuid + "-notes"} className={css["dungeon-guide-notes-row"]}>
                {
                    notesArr.map((entry, i) => {
                        return (
                            <div key={reactKey + "-" + i}>
                                {entry}
                            </div>
                        );
                    })
                }
            </div>
        );
    })

    const RenderDungeonGuideResults = (() => {
        let dungeonGuideRows = [];
        let bossCounter = 0;

        if(dungeonGuideResultsJson[0] !== undefined && dungeonGuideResultsJson[0] !== null)
            dungeonGuideRows.push(<RenderDungeonNotes key={dungeonGuideResultsJson[0].dungeonUuid + "-notes"} />);

        for (let key in dungeonGuideResultsJson) {
            let intKey = parseInt(key);
            let currentRowIsBoss = ((intKey === 0) || (dungeonGuideResultsJson[intKey - 1].bossName !== dungeonGuideResultsJson[intKey].bossName));

            // Added _top_level_row just to ensure there's distinction between the elements rendered at this level, and the children rendered in the styler above
            // This will never be skipped regardless of config settings
            if (currentRowIsBoss) {
                let bossName = "Trash";
                let entryClassName = css["dungeon-guide-trash-row"];

                console.log()
                if (!dungeonGuideResultsJson[intKey].bossIsTrash) {
                    bossName = (ConfigSettings.NoBossSpoilers) ? "Boss #" + ++bossCounter : dungeonGuideResultsJson[intKey].bossName;
                    entryClassName = css["dungeon-guide-boss-row"];
                }

                dungeonGuideRows.push(<RenderDungeonGuideRow key={dungeonGuideResultsJson[intKey].bossUuid} bossName={bossName} className={entryClassName} />);
            }

            if (shouldRowBeHidden(intKey))
                continue;

            let topLevelCssClassNames = [css["dungeon-guide-row"]];
            let itemLevelCssClassNames = [css["indentation-tier-" + dungeonGuideResultsJson[intKey].indentationTier]];

            let styledDescription = DungeonGuideResultsStyler().injectStyling(dungeonGuideResultsJson[intKey].mechUuid, dungeonGuideResultsJson[intKey].description, topLevelCssClassNames, itemLevelCssClassNames);

            // Added _top_level_row just to ensure there's distinction between the elements rendered at this level, and the children rendered in the styler above
            dungeonGuideRows.push(<RenderDungeonGuideRow key={dungeonGuideResultsJson[intKey].mechUuid + "_top_level_row"} description={styledDescription} />);
        }

        return (
            <>
                {dungeonGuideRows}
            </>
        );
    });


    return (
        <>
            <DungeonGuideNavMenu configSettings={ConfigSettings} parentUpdateDungeonGuideResultsState={dungeonGuideResultsStateUpdaterForChild} parentUpdateConfigSettingsState={configSettingsStateUpdaterForChild} />

            <div id={css["spacer-for-nav-menu"]} />

            <div id={css["dungeon-guide-container"]}>
                <RenderDungeonGuideResults />
            </div>
        </>
    );
}

export default FF14DungeonGuide;

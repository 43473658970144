import * as React from 'react';
import { useForm } from 'react-hook-form';

import GetConfigSettings from './GetConfigSettings'
import css from './ConfigPopUpForm.module.css';



const ConfigPopUpForm = (props) => {
    // If clearing local storage is ever needed, use the below line
    //window.localStorage.removeItem("configFormData");

    // The component is guaranteed to return the proper default settings in the event there's a problem with local storage, so just 
    // use these values as the defaults for the form
    let ConfigSettings = GetConfigSettings();

    const formDefaultValues = {
        "briefGuideVersionsCheckbox": ConfigSettings.BriefGuideVersions,
        "noDungeonSpoilersCheckbox": ConfigSettings.NoDungeonSpoilers,
        "noBossSpoilersCheckbox": ConfigSettings.NoBossSpoilers,
        "showTankMechsCheckbox": ConfigSettings.ShowTankMechs,
        "showHealerMechsCheckbox": ConfigSettings.ShowHealerMechs,
        "showPartyMechsCheckbox": ConfigSettings.ShowPartyMechs
    }

    const { register, getValues } = useForm({defaultValues: formDefaultValues});

    const genericFormFieldOnChange = ((e) => {
        window.localStorage.setItem('configFormData', JSON.stringify(getValues()));
        props.parentUpdateConfigSettingsState(GetConfigSettings());
    });

    const RenderConfigForm = (() => {
        return (
            <>
                <form id="config-form">
                    <div id={css["config-pop-up-grid"]}>
                        {/******************************************************************************************************************************/}
                        <div className={css["config-pop-up-grid-row-label"]}>
                            Show Brief Version of Guides
                        </div>

                        <div className={css["config-pop-up-grid-row-input"]}>
                            <input id={css["brief-guide-versions-checkbox"]} {...register("briefGuideVersionsCheckbox",
                                {
                                    onChange: (e) => genericFormFieldOnChange(e)
                                })} type="checkbox" />
                        </div>

                        <div className={css["config-pop-up-grid-row-information"]}>
                            (Requires re-querying current guide)
                        </div>

                        {/******************************************************************************************************************************/}
                        <div className={css["config-pop-up-grid-row-label"]}>
                            No Dungeon Spoilers
                        </div>

                        <div className={css["config-pop-up-grid-row-input"]}>
                            <input id={css["no-dungeon-spoilers-checkbox"]} {...register("noDungeonSpoilersCheckbox",
                                {
                                    onChange: (e) => genericFormFieldOnChange(e)
                                })} type="checkbox" />
                        </div>

                        <div className={css["config-pop-up-grid-row-information"]}>
                            (Top-right search box will mask all matching dungeons)
                        </div>
                        {/******************************************************************************************************************************/}
                        <div className={css["config-pop-up-grid-row-label"]}>
                            No Boss Spoilers
                        </div>

                        <div className={css["config-pop-up-grid-row-input"]}>
                            <input id={css["no-boss-spoilers-checkbox"]} {...register("noBossSpoilersCheckbox",
                                {
                                    onChange: (e) => genericFormFieldOnChange(e)
                                })} type="checkbox" />
                        </div>

                        <div className={css["config-pop-up-grid-row-information"]}>
                            (Boss names will be changed to "Boss #1/2/3" in results)
                        </div>
                        {/******************************************************************************************************************************/}
                        <div className={css["config-pop-up-grid-row-label"]}>
                            Show Tank Mechs
                        </div>

                        <div className={css["config-pop-up-grid-row-input"]}>
                            <input id={css["show-tank-mechs-checkbox"]} {...register("showTankMechsCheckbox",
                                {
                                    onChange: (e) => genericFormFieldOnChange(e)
                                })} type="checkbox" />
                        </div>

                        <div className={css["config-pop-up-grid-row-information"]} />
                        {/******************************************************************************************************************************/}
                        <div className={css["config-pop-up-grid-row-label"]}>
                            Show Healer Mechs
                        </div>

                        <div className={css["config-pop-up-grid-row-input"]}>
                            <input id={css["show-healer-mechs-checkbox"]} {...register("showHealerMechsCheckbox",
                                {
                                    onChange: (e) => genericFormFieldOnChange(e)
                                })} type="checkbox" />
                        </div>

                        <div className={css["config-pop-up-grid-row-information"]} />
                        {/******************************************************************************************************************************/}
                        <div className={css["config-pop-up-grid-row-label"]}>
                            Show Party Mechs
                        </div>

                        <div className={css["config-pop-up-grid-row-input"]}>
                            <input id={css["show-party-mechs-checkbox"]} {...register("showPartyMechsCheckbox",
                                {
                                    onChange: (e) => genericFormFieldOnChange(e)
                                })} type="checkbox" />
                        </div>

                        <div className={css["config-pop-up-grid-row-information"]} />
                        {/******************************************************************************************************************************/}
                    </div>
                </form>
            </>
        );
    });





    return (
        <>
            <RenderConfigForm />
        </>
    );
};

export default ConfigPopUpForm;